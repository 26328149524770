@use '@/styles/utils/mixins.scss' as *;

.pageSection {
  scroll-margin-top: 7.5rem;
}

.contactFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  .formHeaderWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .formHeaderDescription {
      max-width: 25.75rem;
      width: 100%;
    }
  }

  .contactFormContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .formSection {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    grid-column: span 2;
    width: 100%;
    @include tab() {
      grid-column: span 1;
    }
  }

  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }

  .contactFormField {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4.125rem;
    row-gap: 2.5rem;
    &.noGrid {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }
    @include tab-des() {
      column-gap: 2.5rem;
    }

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .float-label {
        .as-label,
        .ant-select-selector,
        .float-input {
          background: $neutral01 !important;
        }
      }
    }
  }

  .acceptTerms {
    max-width: 22rem;
  }
  .contactFormImageContainer {
    max-width: 30rem;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include tab-des() {
      max-width: 24rem;
    }

    img {
      border-radius: 1.25rem;
      object-fit: cover;
    }

    @include tab() {
      display: none;
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
